// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

// Shared Components
import CommonLink from 'src/components/Shared/CommonLink'
import Description from 'src/components/Shared/Description'

const Impact = ({ section }) => {

  if(!section) {
    return null
  }

  return (
    <div className="Impact">
      <div className="Impact__inner">
        <img
          className="Impact__media"
          src={ section.media[0].image.url }
        />

        <div className="Impact__content">
          <h4 className="Impact__content-title">
            { section.title }
          </h4>

          <Description 
            description={ section.description } 
            className="Impact__content-description"
          />

          { 
            section.link &&
              <div className="Impact__link-holder">
                <CommonLink 
                  link={ section.link }
                  className="Impact__link">
                    <span className="icon">
                      <img src={ require("src/images/icons/arrow-right-white.png").default } />
                    </span>
                </CommonLink>
              </div>
          }
        </div>
      </div>


    </div>
  )

}

export default Impact;