// NPM
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash';
import { Link } from 'gatsby';

// Components
import Description from 'src/components/Shared/Description'

const Hero = ({ section }) => {

  if(!section) {
    return null;
  }
  
  return (
    <div className="Hero">
      <div className="Hero__inner">
        <h1 className="Hero__title">
          { section.title }
        </h1>

        <Description 
          description={ section.description } 
          className="Hero__description"
        />

        { 
          section.link && 
            <Link
              className="Hero__cta black-button"
              to={ _.get(section, `link.page.path`, '/') }>
                <span>{ section.link.text }</span>
                <span className="icon">
                  <img src={ require("src/images/icons/arrow-right-white.png").default } />
                </span>
            </Link>
        }
      </div>
    </div>
  )

}

export default Hero