// NPM
import React from 'react';
import _ from 'lodash';

// Components
import Description from 'src/components/Shared/Description'
import OurApproachItemAnimation from './OurApproachItemAnimation'

const OurApproachItem = ({ section, animationFrames }) => {

  if(!section) {
    return null
  }

  return (
    <div className={ `OurApproachItem ${ section.uuid }` }>
      <OurApproachItemAnimation frames={ animationFrames } />

      <h4 className="OurApproachItem__title">
        { section.title }
      </h4>
      
      <Description 
        description={ section.description } 
        className="OurApproachItem__description"
      />
    </div>
  )

}

export default OurApproachItem