import React from "react"

import CommonLink from "src/components/Shared/CommonLink"
import Description from "src/components/Shared/Description"

const DegreeHub = ({ section }) => {
  return (
    <div className="DegreeHubHero">
      <div className="DegreeHubHero__content">
        <div className="DegreeHubHero__logo">
          <img
            src={
              require("src/images/pages/Products/degreehub-logo-white.svg").default
            }
            style={{width: '300px'}}
            alt="Degree Hub"
          />
        </div>
        <div className="DegreeHubHero__text">
          <Description
            description={section.description}
            className="DegreeHubHero__content-description"
          />
          <div className="DegreeHubHero__link-holder">
            <CommonLink link={section.link} className="DegreeHubHero__link">
              <span className="icon">
                <img
                  src={
                    require("src/images/icons/arrow-right-white.png").default
                  }
                  alt="Click to learn more"
                />
              </span>
            </CommonLink>
          </div>
        </div>
        {/* <div className="DegreeHubHero__divider" />
        <div className="DegreeHubHero__stats-wrapper">
          <div className="DegreeHubHero__stats">
            <div className="DegreeHubHero__stats-value">
              {section.statistics[0].value}
            </div>
            <div className="DegreeHubHero__stats-title">
              {section.statistics[0].name}
            </div>
          </div>
          <div className="DegreeHubHero__stats">
            <div className="DegreeHubHero__stats-value">
              {section.statistics[1].value}
            </div>
            <div className="DegreeHubHero__stats-title">
              {section.statistics[1].name}
            </div>
          </div>
        </div> */}
      </div>
      <div className="DegreeHubHero__left-image">
        <img
          src={
            require("src/images/pages/LandingPage/hero-degreehub.png").default
          }
          alt=""
        />
      </div>
    </div>
  )
}

export default DegreeHub;