import React from "react"

import CommonLink from "src/components/Shared/CommonLink"
import Description from "src/components/Shared/Description"

const VoteCompass = ({ section }) => {
  return (
    <div className="VoteCompassHero">
      <div className="VoteCompassHero__left-image">
        <img
          src={
            require("src/images/pages/LandingPage/hero-votecompass.png").default
          }
          alt=""
        />
      </div>
      <div className="VoteCompassHero__content">
        <div className="VoteCompassHero__logo">
          {" "}
          <img
            src={
              require("src/images/pages/Products/votecompass-logo-black.svg").default
            }
            style={{
              filter: "invert(16%) sepia(68%) saturate(1198%) hue-rotate(155deg) brightness(95%) contrast(104%)"
            }}
            alt="Vote Compass"
          />
        </div>
        <div className="VoteCompassHero__text">
          <Description
            description={section.description}
            className="VoteCompassHero__content-description"
          />
          <div className="VoteCompassHero__link-holder">
            <CommonLink link={section.link} className="VoteCompassHero__link">
              <span className="icon">
                <img
                  src={
                    require("src/images/icons/arrow-right-black.png").default
                  }
                  alt="Click to learn more"
                />
              </span>
            </CommonLink>
          </div>
        </div>
        {/* <div className="VoteCompassHero__divider" />
        <div className="VoteCompassHero__stats-wrapper">
          <div className="VoteCompassHero__stats">
            <div className="VoteCompassHero__stats-value">
              {section.statistics[0].value}
            </div>
            <div className="VoteCompassHero__stats-title">
              {section.statistics[0].name}
            </div>
          </div>
          <div className="VoteCompassHero__stats">
            <div className="VoteCompassHero__stats-value">
              {section.statistics[1].value}
            </div>
            <div className="VoteCompassHero__stats-title">
              {section.statistics[1].name}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default VoteCompass;