// NPM
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { create } from '@lottiefiles/lottie-interactivity';
import { Player } from "@lottiefiles/react-lottie-player";

// Constants
import animationData from './animationData';

const OurApproachAnimation = ({ frames }) => {
  const rootRef = useRef(null)
  const [lottiePlayer, setLottiePlayer] = useState(null)

  return (
    <div className="OurApproachItemAnimation" ref={ rootRef }>
      <Player
        lottieRef={(instance) => {
          setLottiePlayer(instance)
        }}
        onEvent={(event) => {
          if (event === "load") {
            create({
              mode: "scroll",
              player: lottiePlayer,
              container: rootRef.current,
              actions: [
                {
                  visibility: [0, 1],
                  type: "seek",
                  frames: frames
                }
              ]
            });
          }
        }}
        autoplay={true}
        loop={false}
        controls={false}
        src={ animationData }
      />
    </div>
  )

}

export default OurApproachAnimation;