// NPM
import React, { useRef, useEffect } from "react"

// Shared Components
import ToolTypes from 'src/components/Shared/ToolTypes';

import WorkTogether from 'src/components/Shared/WorkTogether';
import Partners from 'src/components/Shared/Partners';

// Home Components
import Hero from './Hero';
import OurApproach from './OurApproach';
import VoteCompassHero from './VoteCompassHero';
import DegreeHubHero from "./DegreeHubHero"
import Impact from './Impact';
import Testimonials from './Testimonials';

// Constants
import videoSrc from "src/videos/VP_Hero.mp4";

const Home = ({ sections, testimonials }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    videoRef.current.autoplay = true;
    videoRef.current.loop = true;
    videoRef.current.muted = true; // fixes autoplay in chrome
    videoRef.current.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)


    const source = document.createElement('source');
    source.src = `${ videoSrc }#t=0.5`;
    source.type = 'video/mp4';
    videoRef.current.appendChild(source);

  }, []);

  return (
    <div className="Home">
      <div className="Home__content">
        <Hero section={sections.find(s => s.uuid === "hero")} />

        <Partners section={sections.find(s => s.uuid === "partners")} />
        <div className="Home__gradientbg" />
        <div className="Home__whiteContentWrapper">
          <ToolTypes sections={sections} />
          <VoteCompassHero
            section={sections.find(s => s.uuid === "voteCompass")}
          />
          <DegreeHubHero section={sections.find(s => s.uuid === "degreeHub")} />
          <OurApproach sections={sections} />
          <Impact section={sections.find(s => s.uuid === "impact")} />
          <Testimonials
            section={sections.find(s => s.uuid === "testimonials")}
            testimonials={testimonials}
            autoPlay={true}
          />

          <WorkTogether
            section={sections.find(s => s.uuid === "workTogether")}
          />
        </div>
      </div>

      <video ref={videoRef} className="Home__animation"></video>
    </div>
  )
}

export default Home;