// NPM
import React, { useRef, useState, useEffect } from "react"

// Components
import OurApproachItem from './OurApproachItem'
import OurApproachAnimation from './OurApproachAnimation';

// Constants
import videoSrc from "src/videos/VP_Approach.mp4";

const OurApproach = ({ sections }) => {
  const titleSection = sections.find(s => s.uuid === "ourApproach_top");

  if(!titleSection) {
    return null;
  }

  return (
    <div className="OurApproach">
      <h4 className="OurApproach__title small-header">
        { titleSection.title }
      </h4>

      <div className="OurApproach__content">
        <div className="OurApproach__items">
          <OurApproachItem 
            section={ sections.find(s => s.uuid === "ourApproach_01") } 
            animationFrames={ [0, 480] }
          />
          
          <OurApproachItem 
            section={ sections.find(s => s.uuid === "ourApproach_02") } 
            animationFrames={ [470, 760] }
          />
          
          <OurApproachItem 
            section={ sections.find(s => s.uuid === "ourApproach_03") } 
            animationFrames={ [750, 1069] }
          />
        </div>
        <OurApproachAnimation />
      </div>
      
    </div>
  )

}

export default OurApproach;