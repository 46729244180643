// NPM
import React from 'react'
import Slider from "react-slick"
import _ from 'lodash'

const Testimonials = ({ section, testimonials }) => {

  if(!section) {
    return null;
  }

  const PrevArrow = ({ className, style, onClick }) => {
    return (
      <button
        onClick={ onClick }
        className="Testimonials__carousel-arrow slick-prev"
        style={ style }>
          <img src={ require("src/images/icons/carousel-arrow-left.png").default } />
      </button>
    )
  }

  const NextArrow = ({ className, style, onClick }) => {
    return (
      <button
        onClick={ onClick }
        className="Testimonials__carousel-arrow slick-next"
        style={ style }>
          <img src={ require("src/images/icons/carousel-arrow-right.png").default } />
      </button>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true
  }

  return (
    <div className="Testimonials">
      <div className="Testimonials__content">
        <h4 className="Testimonials__title small-header">
          { section.title }
        </h4>

        <div className="Testimonials__carousel">
          <Slider { ...settings }>
            {
              _.sortBy(testimonials, "order").map((testimonial, testimonialIndex) => {
                return (
                  <div className="Testimonials__carousel-item" key={ `Testimonial-${ testimonialIndex }` }>
                    <p className="Testimonials__carousel-item-text">
                      { testimonial.quote }
                    </p>

                    <p className="Testimonials__carousel-item-credit">
                      { testimonial.credit }
                    </p>
                  </div>
                )
              })
            } 
          </Slider>
        </div>
      </div>
    </div>
  )

}

export default Testimonials